const routes = {
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  VERIFICATION: '/verify',
  FORGOT_PASSWORD: '/forgot-password',
  SETTINGS: '/settings',
  PROFILE: '/profile',
  dashboard: {
    PATIENTS: '/patients',
    PATIENT: '/patients/:patientID',
    HOME: '/home',
    READINGS: '/readings',
    CONSULATION: '/consultation',
  },
};

export default routes;
