import React, { SetStateAction } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
  isActive: boolean | React.Dispatch<SetStateAction<boolean>>;
  content: React.ReactNode;
}

export const ModalPortal: React.FC<ModalProps> = ({ isActive, content }) => {
  if (isActive === false) return <></>;

  return createPortal(
    <div className='absolute top-0 left-0 flex items-center justify-center w-screen h-full bg-[#000a]'>
      <div className='h-full pt-10 w-full'>{content}</div>
    </div>,
    document.body
  );
};
