import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from 'constants/routes';

export const ErrorPage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate(routes.LOGIN);
    })
  }, []);

  return <div>ErrorPage</div>;
};
