const prefillValuesDev = (
  doctorFirstName: string,
  doctorLastName: string,
  doctorEmail: string,
  officeAddress: string,
  officePhone: string,
  officeEmail: string,
  patientFirstName: string,
  patientLastName: string,
  patientEmail: string,
  patientPhone: string
) => {
  // eslint-disable-next-line max-len
  const doctorName = `doctorName[first]=${doctorFirstName}&doctorName[last]=${doctorLastName}&referringDoctors[first]=${doctorFirstName}&referringDoctors[last]=${doctorLastName}&doctorsEmail=${doctorEmail}&hst[cc_firstName]=${doctorFirstName}&hst[cc_lastName]=${doctorLastName}`;
  // eslint-disable-next-line max-len
  const officeData = `referringDoctors45=${officeEmail}&referringDoctors46[full]=${officePhone}&referringDoctors47[full]=${officePhone}&dentalOffice18[full]=${officePhone}&dentalOffice28[full]=${officePhone}&dentalOffice=${officeAddress}&dentalOffice29=${officeEmail}`;
  // eslint-disable-next-line max-len
  const patientData = `patientName[first]=${patientFirstName}&patientName[last]=${patientLastName}&patientEmail=${patientEmail}&patientPhone[full]=${patientPhone}`;

  return `${doctorName}&${officeData}&${patientData}`;
};
const prefillValuesProd = (
  doctorFirstName: string,
  doctorLastName: string,
  doctorEmail: string,
  practiceName: string,
  officePhone: string,
  officeEmail: string,
  patientFirstName: string,
  patientLastName: string,
  patientEmail: string,
  patientPhone: string
) => {
  // eslint-disable-next-line max-len
  const doctorName = `doctorName[first]=${doctorFirstName}&doctorName[last]=${doctorLastName}&referringDoctors[first]=${doctorFirstName}&referringDoctors[last]=${doctorLastName}&doctorsEmail=${doctorEmail}&hst[cc_firstName]=${doctorFirstName}&hst[cc_lastName]=${doctorLastName}&doctorPhone=${officePhone}&doctorFax[full]=${officePhone}`;
  // eslint-disable-next-line max-len
  const officeData = `referringDoctors45=${officeEmail}&referringDoctors46[full]=${officePhone}&referringDoctors47[full]=${officePhone}&dentalOffice18[full]=${officePhone}&dentalOffice16=${officePhone}&practiceName17=${practiceName}&dentalOffice=${officeEmail}&providerOffice21=${officeEmail}`;
  // eslint-disable-next-line max-len
  const patientData = `patientName[first]=${patientFirstName}&patientName[last]=${patientLastName}&patientName3[first]=${patientFirstName}&patientName3[last]=${patientLastName}&patientEmail=${patientEmail}&patientPhone[full]=${patientPhone}&patientMobile[full]=${patientPhone}`;

  return `${doctorName}&${officeData}&${patientData}`;
};

const prefillValues =
  process.env.REACT_APP_AWS_ENV === 'production'
    ? prefillValuesProd
    : prefillValuesDev;

export { prefillValues };
