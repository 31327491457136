import { useState } from 'react';


import { SignUp } from './SignUp';
import { SignUpConfirmation } from './SignUpConfirmation';

import { AuthFormHeader } from '../AuthFormHeader';

type Props = {};

export const Registration = (props: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState<null | string>(null);

  const setValidationStatus = (new_status: boolean) =>
    setIsSubmitted(new_status);
  const cancelSubmission = () => setIsSubmitted(false);

  return (
    <div className='flex justify-center page min-h-screen h-full bg-ghost-white'>

      <AuthFormHeader />

      {isSubmitted ? (
        <SignUpConfirmation onCancel={cancelSubmission} email={email} />
      ) : (
        <SignUp
          setValidationStatus={setValidationStatus}
          updateEmail={setEmail}
        />
      )}
    </div>
  );
};
