import React from 'react';

type Props = {
  icon?: React.ReactNode;
  title: string;
  question: string;
  handleConfirmation: VoidFunction;
  handleRejection: VoidFunction;
  textPositive: string;
  textNegative: string;
};

export const ConfirmationBox = ({
  title,
  icon,
  question,
  handleConfirmation,
  handleRejection,
  textPositive = 'Yes',
  textNegative = 'No',
}: Props) => {
  const onConfirm = () => {
    handleConfirmation();
  };
  const onReject = () => {
    handleRejection();
  };

  return (
    <div className='flex flex-col justify-between items-center max-w-[736px] w-full min-h-[300px] bg-white rounded-lg px-11 py-11'>
      <h2 className='text-primary text-2xl font-medium'>{title}</h2>
      <div className='text-primary text-xl font-medium'>
        <span>{icon || ''}</span>
        {question}
      </div>

      <div className='flex w-full flex-wrap justify-center'>
        <button
          type='button'
          onClick={onConfirm}
          className='mx-5 my-2 bg-red-800 text-white text-xl hover:bg-red-600  px-6 py-4 rounded-full'
        >
          {textPositive}
        </button>
        <button
          type='button'
          onClick={onReject}
          className='mx-5 my-2 bg-police-blue text-white text-xl hover:bg-[#446383] px-6 py-4 rounded-full'
        >
          {textNegative}
        </button>
      </div>
    </div>
  );
};
