import { UseFormRegisterReturn } from 'react-hook-form';

type InputProps = {
  type: string;
  kind: string;
  id: string;
  label: string;
  isRequired: boolean;
  defaultValue: string;
  editEnabled: boolean;
  pattern?: string;
  options?: string[];
  titleForPattern?: string;
  registerProps: UseFormRegisterReturn;
};

export const EditFormField = (props: InputProps) => {
  const {
    type,
    defaultValue,
    id,
    editEnabled,
    isRequired,
    kind,
    registerProps,
    label,
    options,
    ...rest
  } = props;
  const inputClassName = `form__input ${
    !editEnabled ? 'form__input--disabled' : ''
  }`;

  if (kind === 'input') {
    return (
      <>
        <h3 className='text-primary font-medium text-base'>
          <label htmlFor='dob'>
            {label}{' '}
            {editEnabled && isRequired && (
              <span className='text-red-800'>*</span>
            )}
          </label>
        </h3>
        <div
          className={`input_wrapper ${
            editEnabled ? 'input_wrapper--editMode' : ''
          }`}
        >
          <input
            defaultValue={defaultValue}
            {...registerProps}
            name={id}
            id={id}
            type={type}
            required={isRequired}
            disabled={!editEnabled}
            className={inputClassName}
            {...rest}
          />
        </div>
      </>
    );
    //if not input assume that it is select
  } else {
    return (
      <>
        <h3 className='text-primary font-medium text-base'>
          <label htmlFor='methodToContact'>
            {label} {editEnabled && <span className='text-red-800'>*</span>}
          </label>
        </h3>
        <div
          className={`input_wrapper ${
            editEnabled ? 'input_wrapper--editMode' : ''
          }`}
        >
          <select
            defaultValue={defaultValue}
            {...registerProps}
            name={id}
            id={id}
            required={isRequired}
            disabled={!editEnabled}
            className={inputClassName}
          >
            {options &&
              options.map((option) => <option value={option}>{option}</option>)}
          </select>
        </div>
      </>
    );
  }
};
