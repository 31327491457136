import React, { useContext } from 'react';

import { AuthContext } from 'contexts/AuthContext';

export const Header = () => {
  const { currentUser, signOut, profileCompleted } = useContext(AuthContext);

  const closeSession = async () => {
    await signOut();
  };

  if (!profileCompleted) {
    return <div />
  }

  return (
    <header>
      <nav className='bg-white mx-6 mt-8 border-b border-[#AEACAC] h-[50px]'>
        <ul className='w-full flex justify-between min-w-fit'>
          <li className='text-subtitles font-semibold text-3xl'>
            {currentUser ? `Welcome, ${currentUser.attributes.name}!` : 'Loading...'}
          </li>
          <li className='text-subtitles font-semibold text-lg'>
            {' '}
            <button onClick={() => currentUser && closeSession()}>
              {currentUser && 'Log out'}
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};
