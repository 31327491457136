import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';

import { AuthContext } from 'contexts/AuthContext';
import { prettifyErrorMessage } from 'helpers/aws_error_handling';

import { AuthFormTitle } from '../AuthFormTitle';

type Props = {
  onCancel: VoidFunction;
  email: null | string;
};

export const SignUpConfirmation = ({ onCancel, email }: Props) => {
  const { resendConfirmationCode, confirmSignUp } = useContext(AuthContext);
  const [confirmationCode, setConfirmationCode] = useState<null | string>(null);

  const onResendCode = async () => {
    if (email) {
      const promise = async () => await resendConfirmationCode(email);

      toast.promise(promise(), {
        loading: 'Sending verification code',
        success: 'Check your email!',
        error: (err) => `${prettifyErrorMessage(err)}`,
      });
    }
  };

  const handleConfirmation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email && confirmationCode) {
      const promise = async () => await confirmSignUp(email, confirmationCode);

      toast.promise(promise(), {
        loading: 'Verifying your account',
        success: 'Account verified!',
        error: (err) => `${err}`,
      });
    }
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(e.target.value);
  };

  return (
    <div className='flex flex-col flex-1 justify-center items-center w-full h-screen bg-ghost-white'>
      <div className='max-w-xl w-full bg-white rounded-lg px-11 py-11'>
        <AuthFormTitle title='Email confirmation' />

        <div>
          <h3 className='text-subtitles text-lg mb-4 mt-10'>
            Please provide the verification code from the email that was sent to you.
            If you do not see the email in a few minutes, check your “spam” folder.
          </h3>
          <div>
            <form
              className='flex flex-col mt-5'
              onSubmit={handleConfirmation}
            >
              <label
                htmlFor='confirmation'
                className='text-subtitles mb-2 text-lg font-medium'
              >
                Verification code
              </label>
              <input
                type='number'
                name='confirmation'
                id='confirmation'
                onChange={handleCodeChange}
              />
              <span
                className='cursor-pointer text-subtitles hover:underline self-end'
                onClick={onResendCode}
              >
                Resend verification code
              </span>
              <div className='flex mt-5 flex-wrap'>
                <button
                  type='submit'
                  className='rounded-full bg-primary hover:bg-police-blue text-white px-4 py-3 my-2 w-48'
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
  );
};
