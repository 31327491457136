import { Link } from 'react-router-dom';

import routes from 'constants/routes';

export const AuthFormHeader = () => (
  <Link to={routes.LOGIN}>
    <img
      src='/assets/logo/blackLogo.svg'
      alt='logo'
      className='h-[120px] sm:w-[410px] w-[210px]'
  /></Link>
)
