import {
  emailPattern,
  hstDevicesPatter,
  telPattern,
  textFieldPattern,
} from 'helpers/validation';

export const dashboardItems = [
  {
    name: 'Home',
    route: 'home',
    icon: (
      <path d='M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z' />
    ),
  },
  {
    name: 'Patients',
    route: 'patients',
    icon: (
      <path d='M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z' />
    ),
  },
  // {
  //   name: 'Readings',
  //   route: 'readings',
  //   icon: (
  //     <path
  //       fillRule='evenodd'
  //       d='M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Zm6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z'
  //     />
  //   ),
  // },
  // {
  //   name: 'Referrals',
  //   route: 'referrals',
  //   icon: (
  //     <path d='M7 1.414V2H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h5v1H2.5a1 1 0 0 0-.8.4L.725 8.7a.5.5 0 0 0 0 .6l.975 1.3a1 1 0 0 0 .8.4H7v5h2v-5h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H9V6h4.5a1 1 0 0 0 .8-.4l.975-1.3a.5.5 0 0 0 0-.6L14.3 2.4a1 1 0 0 0-.8-.4H9v-.586a1 1 0 0 0-2 0zM13.5 3l.75 1-.75 1H2V3h11.5zm.5 5v2H2.5l-.75-1 .75-1H14z' />
  //   ),
  // },
  {
    name: 'MD Consult',
    route: 'consultation',
    icon: (
      <>
        <path d='M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2Z' />
        <path d='M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783.059-.187.09-.386.09-.593V4a2 2 0 0 0-2-2H2Z' />
      </>
    ),
  },
];
export const footerLinks = [
  {
    title: 'Company',
    links: [
      {
        name: 'Our Mission',
        link: 'https://www.empowersleep.com/about-us',
      },
      {
        name: 'How it Works',
        link: 'https://www.empowersleep.com/how-it-works',
      },
      {
        name: 'FAQs',
        link: 'https://www.empowersleep.com/faqs',
      },
      {
        name: 'Providers',
        link: 'https://www.empowersleep.com/providers',
      },
    ],
  },
  {
    title: 'More',
    links: [
      {
        name: 'Terms',
        link: 'https://www.empowersleep.com/terms-of-use',
      },
      {
        name: 'Privacy',
        link: 'https://www.empowersleep.com/privacy-policy',
      },
      {
        name: 'Help Center',
        link: 'https://guide.empowersleep.com/',
      },
    ],
  },
];

export const contactInfo = [
  { name: 'Email', value: 'care@empowersleep.com' },
  { name: 'Phone/text', value: '(213) 375-4070' },
  { name: 'Fax', value: '(951) 463-4200' },
];

export const signupFields = [
  {
    labelText: 'Email address',
    id: 'email',
    name: 'email',
    type: 'email',
    isRequired: true,
    placeholder: 'Email address',
    pattern: '/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i',
    errorMessage: 'Please provide valid email',
  },
  {
    labelText: 'Password',
    id: 'password',
    name: 'password',
    type: 'password',
    autoComplete: 'current-password',
    isRequired: true,
    placeholder: 'Password',
    pattern: '.{8,}',
    errorMessage: 'Password should be eight or more characters',
  },
  {
    labelText: 'Confirm Password',
    id: 'confirmPassword',
    name: 'confirmPassword',
    type: 'password',
    isRequired: true,
    placeholder: 'Confirm Password',
    pattern: '.{8,}',
    errorMessage: 'Password should be eight or more characters',
  },
];

export const loginFields = [
  {
    labelText: 'Email*',
    id: 'email',
    name: 'email',
    type: 'email',
    isRequired: true,
    placeholder: 'email@example.com',
    pattern: '/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i',
    errorMessage: 'Please provide valid email',
  },
  {
    labelText: 'Password*',
    id: 'password',
    name: 'password',
    type: 'password',
    isRequired: true,
    placeholder: '*****',
    pattern: '.{8,}',
    errorMessage: 'Password should be eight or more characters',
  },
];

export const additionalInformationFields = [
  {
    sectionName: 'Practice information',
    fields: [
      {
        id: 'practiceName',
        name: 'practiceName',
        type: 'text',
        isRequired: true,
        placeholder: 'Practice name',
        pattern: textFieldPattern,
        errorMessage: 'Please, provide valid practice name',
      },
      {
        id: 'frontOfficePointOfContact',
        name: 'frontOfficePointOfContact',
        type: 'text',
        isRequired: true,
        placeholder: 'Front office point of contact',
        pattern: textFieldPattern,
        errorMessage: 'Please, provide valid contact',
      },
      {
        id: 'frontOfficeEmail',
        name: 'frontOfficeEmail',
        type: 'email',
        isRequired: true,
        placeholder: 'Front office email address',
        pattern: emailPattern,
        errorMessage: 'Please provide valid email',
      },
      {
        id: 'officeAddress',
        name: 'officeAddress',
        type: 'text',
        isRequired: true,
        placeholder: 'Address 1, Address 2, City, State, Zipcode',
        pattern: textFieldPattern,
        errorMessage: 'Please, provide valid address',
      },
      {
        id: 'officePhoneNumber',
        name: 'officePhoneNumber',
        type: 'tel',
        isRequired: true,
        placeholder: 'Office Phone Number* ###-###-####',
        pattern: telPattern,
        errorMessage: 'Please, provide valid phone number',
      },
    ],
  },
  {
    sectionName: 'Additional information',
    fields: [
      {
        id: 'firstName',
        name: 'firstName',
        type: 'text',
        isRequired: true,
        placeholder: 'Doctor’s first name',
        pattern: textFieldPattern,
        errorMessage: 'Please, provide valid first name',
      },
      {
        id: 'lastName',
        name: 'lastName',
        type: 'text',
        isRequired: true,
        placeholder: 'Doctor’s last name',
        pattern: textFieldPattern,
        errorMessage: 'Please, provide valid last name',
      },
      {
        id: 'doctorEmail',
        name: 'doctorEmail',
        type: 'email',
        isRequired: true,
        placeholder: 'Doctor’s email',
        pattern: emailPattern,
        errorMessage: 'Please, provide valid email',
      },
      {
        id: 'emergencyContact',
        name: 'emergencyContact',
        type: 'tel',
        isRequired: true,
        placeholder: 'Emergency Contact ###-###-####',
        pattern: telPattern,
        errorMessage: 'Please, rovide valid phone number',
      },
      {
        id: 'hstDevicesInOffice',
        name: 'hstDevicesInOffice',
        type: 'number',
        isRequired: true,
        placeholder: 'Amount of Sleep Test Devices',
        pattern: hstDevicesPatter,
        errorMessage: 'Devices amount should be any number',
      },
    ],
  },
];

export const dateFormat = 'MM/DD/YYYY';

const jotFormsProd = {
  hst: 'https://hipaa.jotform.com/211814702104038',
  ref: 'https://hipaa.jotform.com/210050847821146',
  oralAppliance: 'https://hipaa.jotform.com/212747042746053',
};
const jotFormsTest = {
  hst: 'https://hipaa.jotform.com/222225883204148',
  ref: 'https://hipaa.jotform.com/222226624760149',
  oralAppliance: 'https://hipaa.jotform.com/222225835510145',
};

export const jotFormLinks =
  process.env.REACT_APP_AWS_ENV === 'production' ? jotFormsProd : jotFormsTest;

export const PatientDataInputs = [
  {
    categoryName: 'General information',
    fields: [
      {
        kind: 'input',
        type: 'text',
        id: 'firstName',
        label: 'First name',
        isRequired: true,
      },
      {
        kind: 'input',
        type: 'text',
        id: 'lastName',
        label: 'Last name',
        isRequired: true,
      },
      {
        kind: 'input',
        type: 'text',
        id: 'middleName',
        isRequired: false,
        label: 'Middle name',
      },
      {
        kind: 'input',
        type: 'date',
        id: 'dob',
        isRequired: true,
        label: 'Date of birth',
      },
      {
        kind: 'select',
        type: 'text',
        id: 'gender',
        label: 'Gender',
        isRequired: true,
        options: ['Female', 'Male', 'Other'],
      },
    ],
  },
  {
    categoryName: 'Contact information',
    fields: [
      {
        kind: 'input',
        type: 'tel',
        id: 'phoneNumber',
        label: 'Phone number',
        isRequired: true,
        pattern: '^(+d{1,2}s)?(?d{3})?[s.-]?d{3}[s.-]?d{4}$',
        title: '(###) ###-####',
      },
      {
        kind: 'input',
        type: 'email',
        id: 'email',
        label: 'Email address',
        isRequired: true,
        title: 'example@example.com',
      },
      {
        kind: 'select',
        type: 'text',
        id: 'methodToContact',
        label: 'Method to contact',
        isRequired: true,
        options: ['Phone number', 'Email'],
      },
    ],
  },
  {
    categoryName: 'Sleep information',
    fields: [
      {
        kind: 'input',
        type: 'text',
        id: 'sleepImageID',
        label: 'Sleepimage ID',
        isRequired: false,
      },
    ],
  },
];
