import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import routes from 'constants/routes';
import { AuthContext } from 'contexts/AuthContext';
import { prettifyErrorMessage } from 'helpers/aws_error_handling';

import { AuthFormHeader } from '../AuthFormHeader';
import { AuthFormTitle } from '../AuthFormTitle';

export const LoginPage = () => {
  const [loginData, setLoginData] = useState({ email: '', password: '' });

  const [confirmationCode, setConfirmationCode] = useState<null | string>(null);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const { signIn, currentUser, resendConfirmationCode, confirmSignUp } =
    useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate(routes.dashboard.HOME);
    }
  }, [currentUser, navigate]);

  const handleConfirmation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (confirmationCode) {
      const promise = async () => {
        await confirmSignUp(loginData.email, confirmationCode);
        await signIn(loginData.email, loginData.password);
      };
      toast.promise(promise(), {
        loading: 'Verifying your account',
        success: 'Account verified!',
        error: (err) => `${prettifyErrorMessage(err)}`,
      });
    }
  };

  const resendCode = async (email: string) => {
    if (email) {
      const promise = async () => await resendConfirmationCode(email);

      toast.promise(promise(), {
        loading: 'Sending verification code',
        success: 'Check your email!',
        error: (err) => `${prettifyErrorMessage(err)}`,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const promise = async () => {

      try {
        await signIn(loginData.email, loginData.password)
      } catch (error: any) {
        if (!error.toString().includes('UserNotConfirmedException')) {
          // propagate further
          throw error
        }

        setIsConfirmed(false)
        await resendConfirmationCode(loginData.email)
        throw error
      }
    };

    toast.promise(promise(), {
      loading: 'Loading..',
      success: 'Logged in',
      error: (err) => `${prettifyErrorMessage(err)}`,
    });
  };

  const fieldChangeHandler = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    setLoginData((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const navigateToForgotPage = () => {
    navigate(routes.FORGOT_PASSWORD);
  };

  return (
    <div className='flex flex-col page min-h-screen h-full bg-ghost-white'>

      <AuthFormHeader />

      <div className='flex flex-col flex-1 justify-center items-center w-full h-screen bg-ghost-white'>
        <div className='max-w-xl w-full bg-white rounded-lg px-11 py-11'>
          <AuthFormTitle title='Provider Login' />

          {!isConfirmed && (
          <h3 className='text-subtitles text-lg mb-4 mt-10'>
            Check you email for a verification code.
          </h3>
            )}

          <form
            className='flex flex-col justify-center'
            onSubmit={isConfirmed ? handleSubmit : handleConfirmation}
            action='#'
            onChange={fieldChangeHandler}
          >
            <div className='form-input flex flex-col mt-5'>
              <label
                htmlFor='email'
                className='text-subtitles text-lg font-medium mb-2'
              >
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                required={true}
                placeholder='email@example.com'
              />
            </div>

            <div className='form-input flex flex-col mt-5'>
              <label
                htmlFor='password'
                className='text-subtitles text-lg font-medium mb-2'
                >
                Password
              </label>
              <input
                type='password'
                name='password'
                id='password'
                placeholder='*****'
                autoSave='true'
              />
            </div>
            {isConfirmed && (
              <span
                className='cursor-pointer text-subtitles hover:underline self-end'
                onClick={navigateToForgotPage}
              >
                Forgot password?
              </span>
            )}

            {!isConfirmed && (
              <div className='form-input flex flex-col mt-5'>
                <label
                  htmlFor='confirmation'
                  className='text-subtitles mb-2 font-medium text-lg'
                >
                  Verification code
                </label>
                <input
                  type='number'
                  name='confirmation'
                  id='confirmation'
                  placeholder='****'
                  onChange={(e) => setConfirmationCode(e.target.value)}
                />
                <span
                  className='cursor-pointer text-subtitles hover:underline self-end'
                  onClick={() => resendCode(loginData.email)}
                >
                  Resend verification code
                </span>
              </div>
            )}
            <button
              className='rounded-full bg-primary hover:bg-police-blue text-white px-4 py-3 mt-11 w-48 self-center'
              type='submit'
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
