import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { Dna } from 'react-loader-spinner';

import { ModalPortal } from 'components';
import { AuthContext } from 'contexts/AuthContext';
import { Patient } from 'global/types';
import { useModal } from 'hooks';
import { getPatients } from 'services/awsFunctions';

import { PatientCreateForm, PatientsButtons, PatientsTable } from './Components';

export const PatientsPage = () => {
  const { currentUserUuid } = useContext(AuthContext);
  const { isActive, setIsActive, selectedContent, setSelectedContent } =
    useModal();

  const queryClient = useQueryClient();
  let { data, error, isLoading } = useQuery<Patient[], any>({
    queryKey: ['patients', currentUserUuid],
    queryFn: () => getPatients(currentUserUuid || '')
  });

  const openForm = () => {
    const closeForm = () => setIsActive(false);
    if (currentUserUuid) {
      setSelectedContent(
        <PatientCreateForm
          doctorId={currentUserUuid}
          onCreated={(patient) => {
            queryClient.invalidateQueries({
              queryKey: ['patients', currentUserUuid]
            });
            closeForm();
          }}
          onClose={closeForm}
        />
      );
      setIsActive(true);
    }
  };

  return (
    <>
      <ModalPortal content={selectedContent} isActive={isActive} />
      <PatientsButtons openForm={openForm} />

      {data !== undefined &&
      <PatientsTable patients={data} />}

      {isLoading &&
      <div className='flex flex-col items-start h-full w-full'>
        <Dna
          visible={true}
          height='100'
          width='100'
          ariaLabel='dna-loading'
          wrapperStyle={{}}
          wrapperClass='dna-wrapper'
        />
      </div>}

      {!isLoading && error &&
      <p>Error loading patients.</p>}
    </>
  );
};
