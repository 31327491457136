import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import routes from 'constants/routes';
import { AuthContext } from 'contexts/AuthContext';

export const WelcomeLayout: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      {currentUser ? (
        <>
          {' '}
          <div className='h-full w-full flex flex-row min-h-screen'>
            <div className='w-full h-full text-subtitles'>
              <div className='flex flex-col'>
                <div className='outlet-wrapper overflow-y-auto overflow-x-hidden'>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Navigate to={routes.LOGIN} />
      )}
    </>
  );
};
