/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "empowersleepAPI",
            "endpoint": "https://g2dueez7yi.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:7d1e4d6c-6131-4aad-a166-f24e14eb5bad",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_iRF526die",
    "aws_user_pools_web_client_id": "268pl7kcb827brbbasj20l8r8f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "doctors-production",
            "region": "us-east-1"
        },
        {
            "tableName": "patients-production",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
