import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from 'constants/routes';
import { AuthContext } from 'contexts/AuthContext';
import { prettifyErrorMessage } from 'helpers/aws_error_handling';

import { AuthFormHeader } from '../AuthFormHeader';
import { AuthFormTitle } from '../AuthFormTitle';

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [confirmationCode, setConfirmationCode] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);

  const [isConfirmationSent, setIsConfirmationSent] = useState(false);
  const { requestPasswordRecoveryCode, submitForgotPassword } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmitCodeRequest = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (email) {
      const params = {
        loading: 'Sending verification code...',
        success: () => {
          setIsConfirmationSent(true);
          return 'Check your email for the verification code!';
        },
        error: (error: string) => {
          setIsConfirmationSent(false);
          return `${prettifyErrorMessage(error)}`;
        },
      };
      await requestPasswordRecoveryCode(params, email);
    }
  };
  const handleSubmitPasswordRecovery = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    if (confirmationCode && newPassword && email)
      await submitForgotPassword(email, confirmationCode, newPassword);
  };
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const instructions = !isConfirmationSent
    ? 'We will send a verification code to your email.'
    : 'Check you email for a verification code.'

  const navigateToLogin = () => {
    navigate(routes.LOGIN);
  };

  return (
    <div className='flex justify-center page min-h-screen h-full bg-ghost-white'>

      <AuthFormHeader />

      <div className='flex flex-col flex-1 justify-center items-center w-full h-screen bright-gray'>
        <div className='max-w-xl w-full bg-white rounded-lg px-11 py-11'>
          <AuthFormTitle title='Password recovery' /
          >
          <div className='flex items-center'>
            {' '}
            <h3 className='text-subtitles text-lg mb-4 mt-10'>
              {instructions}
            </h3>
          </div>
          {!isConfirmationSent ? (
            <form
              className='w-full flex flex-col justify-center'
              onSubmit={handleSubmitCodeRequest}
              action='#'
            >
              <div className='form-input flex flex-col mt-2'>
                <label
                  htmlFor='email'
                  className='text-subtitles mb-2 text-lg font-medium'
                >
                  Email
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  required={true}
                  placeholder='email@example.com'
                  onChange={handleChangeEmail}
                />
              </div>
              <button
                className='rounded-full bg-primary hover:bg-police-blue text-white px-4 py-3 mt-8 w-50 self-center'
                type='submit'
              >
                Request verification code
              </button>
              <h3
                className='text-subtitles mt-10 cursor-pointer hover:underline'
                onClick={navigateToLogin}
              >
                « Back to Login
              </h3>

            </form>
          ) : (
            <form
              className='w-full flex flex-col justify-center'
              onSubmit={handleSubmitPasswordRecovery}
              action='#'
            >
              <div className='form-input flex flex-col mt-2'>
                <label
                  htmlFor='confirmation'
                  className='text-subtitles mb-2'
                >
                  Verification code:
                </label>
                <input
                  type='number'
                  id='confirmation'
                  name='confirmation'
                  required={true}
                  onChange={handleCodeChange}
                />
              </div>
              <div className='form-input flex flex-col mt-2'>
                <label
                  htmlFor='newPassword'
                  className='text-subtitles mb-2'
                >
                  New password:
                </label>
                <input
                  type='password'
                  id='newPassword'
                  name='newPassword'
                  required={true}
                  onChange={handlePasswordChange}
                />
              </div>
              <h3
                className='text-subtitles font-medium text-base mt-7 cursor-pointer hover:underline'
                onClick={navigateToLogin}
              >
                « Back to Login
              </h3>
              <button
                className='rounded-full bg-primary hover:bg-police-blue text-white px-4 py-3 mt-8 w-48 self-center'
                type='submit'
              >
                Submit new password
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
