type Props = {
  link: string;
  closeFrame: VoidFunction;
};

export const Frame = ({ link, closeFrame }: Props) => {
  return (
    <div className='flex flex-col justify-between items-center max-w-[736px] w-full min-h-[590px] bg-white rounded-lg px-5 pb-6 pt-4 mb-0 mt-[-15px] mx-auto'>
      <div className=' w-full flex items-end justify-end mb-2'>
        <button onClick={closeFrame}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='currentColor'
            className='bi bi-x-lg cursor-pointer text-primary'
            viewBox='0 0 16 16'
          >
            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
          </svg>
        </button>
      </div>
      <iframe
        src={link}
        title='jotform_frame'
        width={'696px'}
        height={'560px'}
        className=''
      />
    </div>
  );
};
