import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Header } from 'components';
import routes from 'constants/routes';
import { AuthContext } from 'contexts/AuthContext';

import { DashboardNavigation } from './Components/Dashboard/DashboardNavigation';

export const DashboardLayout: React.FC = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      {currentUser ? (
        <>
          {' '}
          <div className='h-full w-full flex flex-row min-h-screen'>
            <DashboardNavigation />
            <div className='w-full h-full text-subtitles'>
              <div className='flex flex-col'>
                <Header />
                <div className='outlet-wrapper overflow-y-auto overflow-x-hidden px-10 py-5'>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Navigate to={routes.LOGIN} />
      )}
    </>
  );
};
