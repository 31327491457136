import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

import { dateFormat } from 'constants/data';
import { Patient } from 'global/types';

export const TableRow = ({
  firstName,
  middleName,
  lastName,
  dob,
  gender,
  sleepImageID,
  email,
  patientID,
}: Patient) => {
  const navigate = useNavigate();

  return (
    <tr
      className='bg-[#EBEDEE] text-primary text-base font-medium font-sans cursor-pointer'
      onClick={() => navigate(`/patients/${patientID}`)}
    >
      <th scope='row' className='py-4 px-6 font-medium whitespace-nowrap'>
        {firstName} {middleName} {lastName}
      </th>

      <td className='py-4 px-6'>{moment(dob).format(dateFormat)}</td>
      <td className='py-4 px-6'>
        {typeof gender === 'string' ? gender : 'not specified'}
      </td>
      <td className='py-4 px-6'>{sleepImageID || ''}</td>
      <td className='py-4 px-6 text-center text-primary'>
        <Link className='hover:underline' to={`/patients/${email}`}>
          Patient details
        </Link>
      </td>
    </tr>
  );
};
