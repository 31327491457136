import React from 'react';
import { toast } from 'react-hot-toast';
import { v4 } from 'uuid';

import { Patient } from 'global/types';
import { addPatient } from 'services/awsFunctions';

import { PatientForm, PatientFormValues } from '../PatientForm/PatientForm';

interface PatientCreateFormProps {
  doctorId: string;
  onCreated: (patient: Patient) => void;
  onClose: () => void;
}

export const PatientCreateForm: React.FC<PatientCreateFormProps> = ({
  doctorId,
  onCreated,
  onClose
}) => {
  const handleSubmit = (values: PatientFormValues) => {
    const patient: Patient = {
      ...values,
      doctorID: doctorId,
      patientID: `patient_${v4()}`,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString()
    }
    toast.promise<{data: Patient}>(addPatient(patient), {
      loading: 'Adding a patient...',
      success: ({ data }) => {
        onCreated(data);
        return 'Patient added.';
      },
      error: 'Error occured, please try again later.',
    });
  }

  return (
    <div className='max-w-[736px] w-full bg-white rounded-lg px-11 py-11 my-0 mx-auto'>
      <div className='flex justify-between'>
        <div className='flex items-center mb-4'>
          <img
            src='/assets/logo/iconLogo.svg'
            alt='icon logo'
            className='w-[17px] h-[29px] mr-3'
          />

          <h2 className='text-2xl text-primary'>Create new Patient</h2>
        </div>
        <button onClick={onClose}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='25'
            fill='currentColor'
            className='bi bi-x-lg cursor-pointer'
            viewBox='0 0 16 16'
          >
            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
          </svg>
        </button>
      </div>
      <span className='mx-3 text-md text-primary'>* - required field</span>
      <PatientForm
        onSubmit={handleSubmit}
        submitLabel='Add Patient'
      />
    </div>
  );
}
