import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

interface RadioInputProps<T extends FieldValues, TName extends FieldPath<T>> {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  name: TName;
  control: Control<T>;
  rules?: RegisterOptions<T, TName>;
  options: Record<string, string>;
}

export const RadioInput = <T extends FieldValues, TName extends FieldPath<T>>(
  props: RadioInputProps<T, TName>
) => {
  const {
    className,
    labelClassName,
    inputClassName,
    name,
    control,
    rules,
    options
  } = props;

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({
        field: { onChange, onBlur, value }
      }) => (
        <div className={className}>
          {Object.keys(options).map((key, index) => {
            return (
              <label key={index} className={labelClassName}>
                <input
                  className={inputClassName}
                  type='radio'
                  name={name}
                  value={key}
                  onBlur={onBlur}
                  onChange={onChange}
                  checked={key === value}
                />
                <span>{options[key]}</span>
              </label>
            )
          })}
        </div>
      )}
    />
  );
}
