import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { EditFormField } from 'components/EditFormField/EditFormField';
import { PatientDataInputs } from 'constants/data';
import { Patient } from 'global/types';
import { normalizeUSPhoneNumber } from 'helpers/validation';

interface Props {
  currentPatient: any;
  editMode: boolean;
  onCancel: VoidFunction;
  onSave: (patient: Patient) => void;
}

export const PatientEditForm = ({
  currentPatient,
  editMode,
  onCancel,
  onSave,
}: Props) => {
  const { register, setValue, handleSubmit, reset } = useForm({
    defaultValues: currentPatient,
  });

  const onSubmit: SubmitHandler<Patient> = (data) => {
    const newPatient = { ...currentPatient, ...data, updatedAt: new Date() };
    onSave(newPatient);
  };

  const handleCancel = () => {
    onCancel();
    //to back initial values
    reset(currentPatient);
  };

  useEffect(() => {
    // to apply default values on the initial load
    reset({ ...currentPatient });
  }, [currentPatient, reset]);

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='mt-6 flex md:flex-row flex-col justify-start flex-wrap'>
        {PatientDataInputs[0].fields.map((field) => {
          const registerForInput = { ...register(field.id) };
          return (
            <div className='flex-1 mx-3' key={field.id}>
              <EditFormField
                kind={field.kind}
                key={field.id}
                type={field.type}
                id={field.id}
                label={field.label}
                options={field.options}
                isRequired={field.isRequired}
                defaultValue={currentPatient ? currentPatient[field.id] : ''}
                editEnabled={editMode}
                registerProps={registerForInput}
              />
            </div>
          );
        })}
      </div>
      {/* TODO: finish with reusable inputs and reduce the amount of code */}
      <div className='flex mt-10 flex-wrap'>
        <div className='flex-1'>
          {' '}
          <div className='bg-primary rounded-sm h-8 text-white text-lg font-medium py-1 pl-3 flex items-center mr-3'>
            Contact information
          </div>
          <div className='mt-6 flex md:flex-row flex-col'>
            <div className='flex-1 mr-3'>
              <h3 className='text-primary font-medium text-base'>
                <label htmlFor='phoneNumber'>
                  Phone number{' '}
                  {editMode && <span className='text-red-800'>*</span>}
                </label>
              </h3>
              <div
                className={`input_wrapper ${
                  editMode ? 'input_wrapper--editMode' : ''
                }`}
              >
                <input
                  defaultValue={currentPatient?.phoneNumber}
                  {...register('phoneNumber')}
                  name='phoneNumber'
                  id='phoneNumber'
                  disabled={!editMode}
                  type={'tel'}
                  required
                  className={`form__input ${
                    !editMode ? 'form__input--disabled' : ''
                    }`}
                  placeholder='+1 (987) 123-4567'
                  onBlur={(e) => {
                    setValue('phoneNumber', normalizeUSPhoneNumber(e.target.value));
                  }}
                />
              </div>
            </div>
            <div className='flex-1 mx-3'>
              <h3 className='text-primary font-medium text-base'>
                <label htmlFor='email'>
                  Email address{' '}
                  {editMode && <span className='text-red-800'>*</span>}
                </label>
              </h3>
              <div
                className={`input_wrapper ${
                  editMode ? 'input_wrapper--editMode' : ''
                }`}
              >
                <input
                  defaultValue={currentPatient?.email}
                  {...register('email')}
                  name='email'
                  id='email'
                  disabled={!editMode}
                  type={'email'}
                  required
                  className={`form__input ${
                    !editMode ? 'form__input--disabled' : ''
                  }`}
                  title='example@example.com'
                />
              </div>
            </div>
            <div className='flex-1 mx-3'>
              <h3 className='text-primary font-medium text-base'>
                <label htmlFor='methodToContact'>
                  Method to contact{' '}
                  {editMode && <span className='text-red-800'>*</span>}
                </label>
              </h3>
              <div
                className={`input_wrapper ${
                  editMode ? 'input_wrapper--editMode' : ''
                }`}
              >
                <select
                  defaultValue={currentPatient?.methodToContact}
                  {...register('methodToContact')}
                  name='methodToContact'
                  id='methodToContact'
                  required
                  className={`border-none bg-transparent py-0 w-full ${
                    !editMode ? 'appearance-none opacity-100' : ''
                  }`}
                  disabled={!editMode}
                >
                  <option value='Phone number'>Phone number</option>
                  <option value='Email'>Email</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className='flex-1'>
          {' '}
          <div className='bg-primary rounded-sm h-8 text-white text-lg font-medium py-1 pl-3 flex items-center ml-3'>
            <label htmlFor='sleepImageID'>Sleep information</label>
          </div>
          <div className='mt-6 flex md:flex-row flex-col'>
            <div className='flex-1 mx-3'>
              <h3 className='text-primary font-medium text-base'>
                Sleepimage ID
              </h3>
              <div
                className={`input_wrapper ${
                  editMode ? 'input_wrapper--editMode' : ''
                }`}
              >
                <input
                  defaultValue={currentPatient?.sleepImageID || ''}
                  {...register('sleepImageID')}
                  name='sleepImageID'
                  id='sleepImageID'
                  disabled={!editMode}
                  type={'text'}
                  className={`form__input ${
                    !editMode ? 'form__input--disabled' : ''
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {editMode && (
        <div className='flex md:flex-row flex-col flex-1 justify-center mt-14'>
          <button
            type='submit'
            className='mx-5 bg-police-blue text-white hover:bg-[#446383] px-4 py-2 rounded-full'
          >
            Save changes
          </button>
          <button
            type='button'
            onClick={handleCancel}
            className='mx-5 bg-red-800 text-white hover:bg-red-600  px-4 py-2 rounded-full'
          >
            Cancel
          </button>
        </div>
      )}
    </form>
  );
};
