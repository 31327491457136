import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Dna } from 'react-loader-spinner';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ModalPortal } from 'components';
import { prefillValues } from 'constants/jotform_prefill_fields';
import { AuthContext } from 'contexts/AuthContext';
import { Patient } from 'global/types';
import { useModal } from 'hooks';
import {
  deletePatient,
  getPatient,
  updatePatient,
} from 'services/awsFunctions';

import { PatientButtons, PatientEditForm } from './Components';

export const PatientPage = () => {
  const navigate = useNavigate();
  const { isActive, setSelectedContent, selectedContent, setIsActive } =
    useModal();

  const [editMode, setEditMode] = useState(false);
  const { currentUserUuid, currentProfile } = useContext(AuthContext);
  const { patientID } = useParams();

  const queryClient = useQueryClient();
  let { data: currentPatient, error, isLoading } = useQuery<Patient, any>({
    queryKey: ['patient', currentUserUuid, patientID],
    queryFn: () => getPatient(currentUserUuid || '', patientID || '')
  });

  const initializeDeletePatient = async () => {
    const promise = async () => {
      if (currentUserUuid && currentPatient?.patientID) {
        await deletePatient(
          currentUserUuid,
          currentPatient.patientID
        );
      }
    }
    toast.promise(promise(), {
      loading: 'Deleting a patient',
      success: (data) => {
        queryClient.invalidateQueries({
          queryKey: ['patients', currentUserUuid]
        });
        navigate('/patients');
        return 'Patient deleted';
      },
      error: (err) => `${err}`,
    });
  };

  //Updating patient
  const handleSave = (patientToUpdate: Patient) => {
    toast.promise<Patient>(updatePatient(patientToUpdate), {
      loading: 'Updating the patient...',
      success: (patient) => {
        queryClient.invalidateQueries({
          queryKey: ['patients', currentUserUuid]
        });
        queryClient.invalidateQueries({
          queryKey: ['patient', currentUserUuid, patientID]
        });
        setEditMode(false);
        return 'Patient updated';
      },
      error: 'Error occured, please try again later',
    });
  };

  //For patient buttons component
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const propsForConfirmationBox = {
    title: 'Delete patient',
    question: 'Do you want to delete this patient?',
    handleConfirmation: initializeDeletePatient,
    handleRejection: () => setIsActive(false),
    textPositive: 'Delete',
    textNegative: 'Cancel',
  };

  const params = {
    doctorFirstName: currentProfile.firstName || '',
    doctorLastName: currentProfile.lastName || '',
    doctorEmail: currentProfile.doctorEmail,
    practiceName: currentProfile.practiceName,
    officePhone: currentProfile.officePhoneNumber,
    officeEmail: currentProfile.frontOfficeEmail,
    patientFirstName: currentPatient?.firstName || '',
    patientLastName: currentPatient?.lastName || '',
    patientEmail: currentPatient?.email || '',
    patientPhone: currentPatient?.phoneNumber || '',
  };

  const prefilledValues = prefillValues(
    params.doctorFirstName,
    params.doctorLastName,
    params.doctorEmail,
    params.practiceName,
    params.officePhone,
    params.officeEmail,
    params.patientFirstName,
    params.patientLastName,
    params.patientEmail,
    params.patientPhone
  );

  const closeModal = () => setIsActive(false);

  return (
    <div>
      <ModalPortal isActive={isActive} content={selectedContent} />
      <PatientButtons
        propsForConfirmationBox={propsForConfirmationBox}
        prefilledValues={prefilledValues}
        openModal={() => setIsActive(true)}
        closeModal={closeModal}
        toggleEditMode={toggleEditMode}
        setSelectedContent={setSelectedContent}
      />
      <Link to={'/patients'}> « Back to the patients table</Link>
      <div className='mt-5'>
        {editMode && (
          <span className='text-md text-primary'>
            <span className=' text-red-800'>*</span> - required field
          </span>
        )}
        <div className='bg-primary rounded-sm h-8 text-white text-lg font-medium py-1 pl-3 mt-4 flex items-center'>
          General information
        </div>
        <div>
          {currentPatient &&
          <PatientEditForm
            editMode={editMode}
            currentPatient={currentPatient}
            onCancel={toggleEditMode}
            onSave={handleSave}
            />}

          {isLoading &&
          <div className='flex flex-col items-start h-full w-full'>
            <Dna
              visible={true}
              height='100'
              width='100'
              ariaLabel='dna-loading'
              wrapperStyle={{}}
              wrapperClass='dna-wrapper'
            />
          </div>}

          {!isLoading && error &&
          <p>Error loading patient data.</p>}
        </div>
      </div>
    </div>
  );
};
