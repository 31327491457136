import React from 'react';
import { useForm } from 'react-hook-form';

import { FormField } from 'components/FormField/FormField';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { emailPattern, ErrorMessages, normalizeUSPhoneNumber } from 'helpers/validation';

interface PatientFormProps {
  initialValues?: PatientFormValues;
  onSubmit: (values: PatientFormValues) => void;
  submitLabel: string;
}

export interface PatientFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  dob: string;
  phoneNumber: string;
  gender: 'Male' | 'Female' | 'Other';
  sleepImageID: string;
  methodToContact: string;
}

const formFieldProps = {
  formName: 'patient-form',
  className: 'form-input flex flex-col mb-5',
  labelClassName: 'text-subtitles font-medium',
  errorClassName: 'text-sm text-red-600 mt-1'
}

const radioInputProps = {
  labelClassName: 'text-subtitles font-medium mr-4',
  inputClassName: 'mr-1'
}

const PatientFormHeader: React.FC<{title: string}> = ({ title }) => (
  <h2 className='text-subtitles font-medium mb-4 text-xl'>
    {title}
  </h2>
);

export const PatientForm: React.FC<PatientFormProps> = ({
  initialValues,
  onSubmit,
  submitLabel
}) => {
  const defaultValues = initialValues || {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    dob: '',
    phoneNumber: '',
    gender: 'Male',
    sleepImageID: '',
    methodToContact: 'Phone number'
  };

  const { register, control, setValue, handleSubmit, formState: { errors } } = useForm<PatientFormValues>({
    defaultValues
  });

  return (
    <form
      className='w-full flex flex-col justify-center pt-4'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-row flex-wrap'>
        <div className='flex-1 flex flex-col mx-3'>
          <PatientFormHeader title='General Information' />

          <FormField {...formFieldProps}
            name='firstName'
            label='First Name'
            errors={errors}
            required
            render={(name, id) =>
              <input
                {...register(name, {
                  required: ErrorMessages.REQUIRED
                })}
                id={id}
                type='text'
                placeholder='Jane'
              />
            }
          />

          <FormField {...formFieldProps}
            name='lastName'
            label='Last Name'
            errors={errors}
            required
            render={(name, id) =>
              <input
                {...register(name, {
                  required: ErrorMessages.REQUIRED
                })}
                id={id}
                type='text'
                placeholder='Doe'
              />
            }
          />

          <FormField {...formFieldProps}
            name='middleName'
            label='Middle Name'
            render={(name, id) =>
              <input
                {...register(name)}
                id={id}
                type='text'
                placeholder='Emily'
              />
            }
          />

          <FormField {...formFieldProps}
            name='dob'
            label='Date of Birth'
            errors={errors}
            required
            render={(name, id) =>
              <input
                {...register(name, {
                  required: ErrorMessages.REQUIRED
                })}
                id={id}
                type='date'
              />
            }
          />

          <FormField {...formFieldProps}
            name='gender'
            label='Gender'
            errors={errors}
            required
            render={(name) =>
              <RadioInput {...radioInputProps}
                name={name}
                control={control}
                rules={{
                  required: ErrorMessages.REQUIRED
                }}
                options={{
                  'Male': 'Male',
                  'Female': 'Female',
                  'Other': 'Other'
                }}
              />
            }
          />
        </div>
        <div className='flex-1 flex flex-col mx-3'>
          <PatientFormHeader title='Contact Information' />

          <FormField {...formFieldProps}
            name='phoneNumber'
            label='Phone Number'
            errors={errors}
            required
            render={(name, id) =>
              <input
                {...register(name, {
                  required: ErrorMessages.REQUIRED
                })}
                id={id}
                type='phone'
                placeholder='(987) 123-4567'
                onBlur={(e) => {
                  setValue(name, normalizeUSPhoneNumber(e.target.value));
                }}
              />
            }
          />

          <FormField {...formFieldProps}
            name='email'
            label='Email Address'
            errors={errors}
            required
            render={(name, id) =>
              <input
                {...register(name, {
                  required: ErrorMessages.REQUIRED,
                  pattern: {
                    value: emailPattern,
                    message: 'Email address is invalid.'
                  }
                })}
                id={id}
                type='email'
                placeholder='example@example.com'
              />
            }
          />

          <FormField {...formFieldProps}
            name='methodToContact'
            label='Preferred Method to Contact'
            errors={errors}
            required
            render={(name) =>
              <RadioInput {...radioInputProps}
                name={name}
                control={control}
                rules={{
                  required: ErrorMessages.REQUIRED
                }}
                options={{
                  'Phone number': 'Phone number',
                  'Email': 'Email'
                }}
              />
            }
          />

          <FormField {...formFieldProps}
            name='sleepImageID'
            label='Sleep Image ID'
            errors={errors}
            render={(name, id) =>
              <input
                {...register(name)}
                id={id}
                type='text'
                placeholder='12345'
              />
            }
          />
        </div>
      </div>
      <button
        className='rounded-full bg-primary hover:bg-police-blue text-white px-4 py-3 mt-2 w-48 self-center'
        type='submit'
      >
        {submitLabel}
      </button>
    </form>
  );
}
