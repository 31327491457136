import React, { FormEvent, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Dna } from 'react-loader-spinner';

import { AuthContext } from 'contexts/AuthContext';
import { getDoctor, updateDoctor } from 'services/awsFunctions';

export const HomePage = () => {
  const {
    currentUser,
    profileCompleted,
    updateUserFullName,
    currentProfile,
    updateProfile,
    currentUserUuid
  } = useContext(AuthContext);
  const [editModeProfileData, setEditModeProfileData] = useState(!profileCompleted);

  //TODO: Refactoring here with use form
  const updateUserProfileData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const data: any = {
      ID: currentUser.attributes['custom:uuid'],
      email: currentUser.attributes.email,
      updatedAt: new Date().toISOString(),
      createdAt: currentProfile
        ? currentProfile.createdAt
        : new Date().toISOString(),
    };
    for (let i = 0; i < form.elements.length; i++) {
      const elem = form.elements[i] as any;
      if (elem.name.length !== 0) {
        data[elem.name] = elem.value;
      }
    }
    updateUserFullName(`${data.firstName} ${data.lastName}`)
    toast.promise(updateDoctor(data), {
      loading: 'Updating profile...',
      success: () => {
        toggleProfileEdit();
        getDoctor(currentUserUuid as string).then(async (data) => {
          updateProfile(data[0]);
        });

        return 'Profile updated';
      },
      error: 'Error occured, please try again later',
    });
  };

  const toggleProfileEdit = () => {
    setEditModeProfileData((prev) => (!prev || !profileCompleted));
  };

  const requiredAttributeClass = editModeProfileData ? 'text-red-800' : 'hidden';

  const completeProfileOuterColumnClasses = !profileCompleted
    ? 'flex flex-col justify-center items-center p-10 w-full bg-ghost-white'
    : ''

  const completeProfileInnerColumnClasses = !profileCompleted
    ? 'max-w-xl w-full bg-white rounded-lg p-10'
    : 'max-w-xl w-full bg-white pr-10'

  return (
    <div>
      {currentUser && currentProfile ? (
        <div className={completeProfileOuterColumnClasses}>
          <div className={completeProfileInnerColumnClasses}>
            <form action='#' onSubmit={(e) => updateUserProfileData(e)} onReset={toggleProfileEdit}>
              <div className='mb-2'>

              {profileCompleted && currentProfile ? (
                <div/>
                ) : (
                  <>
                    <h1 className='font-semibold text-3xl border-b border-[#AEACAC]'>Welcome</h1>
                    <div className='text-primary text-medium pt-10 leading-8'>
                        Tell us about your practice, and share your contact information.<br />
                        We may reach out to collect patient history and
                        will share visit details back to you in timely manner.
                    </div>
                  </>
                )
              }
              </div>

              {profileCompleted && (
                <div>
                  <div>
                    <h2 className='text-primary text-large font-medium pt-2 mr-2 my-2'>Username</h2>
                  </div>
                  <input
                      type='text'
                      defaultValue={currentUser.attributes.email}
                      id='email'
                      name='email'
                      className='form__input form__input--disabled bg-white'
                      disabled
                      required
                    />
                </div>
              )}
              <div>
                <div className='py-8'>
                  <div className='flex'>
                    <h2 className='text-primary text-large font-medium pt-2 grow'>Provider information</h2>

                    {profileCompleted && (
                    <button
                      type='button'
                      className='ml-2'
                      title='Edit provider’s profile data'
                      onClick={toggleProfileEdit}>
                        <span className='sr-only'>Click to edit</span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          fill='currentColor'
                          className='bi bi-pencil-square'
                          viewBox='0 0 16 16'
                        >
                          <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                          <path
                            fillRule='evenodd'
                            d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z' />
                        </svg>
                    </button>
                    )}
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      First name
                      <span className={requiredAttributeClass}>{' '}*</span>
                      :
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.firstName}
                      id='firstName'
                      name='firstName'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Last name<span className={requiredAttributeClass}> *</span>:
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.lastName}
                      id='lastName'
                      name='lastName'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Email
                      <span className={requiredAttributeClass}> *</span>
                      :
                    </h3>
                    <input
                      type='email'
                      defaultValue={currentProfile.doctorEmail}
                      id='doctorEmail'
                      name='doctorEmail'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div className='mt-8'>
                    <h2 className='text-primary text-large font-medium'>
                      Practice
                    </h2>
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Name<span className={requiredAttributeClass}>*</span>:
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.practiceName}
                      id='practiceName'
                      name='practiceName'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Emergency phone number
                      <span className={requiredAttributeClass}> *</span>
                      :
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.emergencyContact}
                      id='emergencyContact'
                      name='emergencyContact'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Number of reusable Home Sleep Test in the office
                      <span className={requiredAttributeClass}>*</span>
                      :
                      </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.hstDevicesInOffice}
                      id='hstDevicesInOffice'
                      name='hstDevicesInOffice'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div className='mt-8'>
                    <h2 className='text-primary text-large font-medium'>
                      Office
                    </h2>
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Phone number
                      <span className={requiredAttributeClass}> *</span>
                      :
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.officePhoneNumber}
                      id='officePhoneNumber'
                      name='officePhoneNumber'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Front office email
                      <span className={requiredAttributeClass}> *</span>
                      :
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.frontOfficeEmail}
                      id='frontOfficeEmail'
                      name='frontOfficeEmail'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Front office point of contact
                      <span className={requiredAttributeClass}> *</span>
                      :
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.frontOfficePointOfContact}
                      id='frontOfficePointOfContact'
                      name='frontOfficePointOfContact'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
                  <div>
                    <h3 className='mt-2'>
                      Mailing address
                      <span className={requiredAttributeClass}> *</span>
                      :
                    </h3>
                    <input
                      type='text'
                      defaultValue={currentProfile.officeAddress}
                      id='officeAddress'
                      name='officeAddress'
                      className={'form__input'}
                      disabled={!editModeProfileData}
                      required
                    />
                  </div>
              </div>
            </div>
          {editModeProfileData && (
            <span className='text-md text-primary'>
              <span className='text-red-800'>*</span> - required field
            </span>
          )}
          {editModeProfileData && profileCompleted && (
            <div className='my-5'>
              <button
                type='submit'
                className='bg-primary text-white hover:bg-[#446383] font-medium px-6 py-4 mr-1 rounded-full'
              >
                Save
              </button>
              <button
                type='reset'
                className=' bg-red-700 text-white hover:bg-red-600 font-medium px-6 py-4 mx-2 rounded-full'
              >
                Cancel
              </button>
            </div>
            )}
          {editModeProfileData && !profileCompleted && (
            <div className='mt-5'>
              <button
                type='submit'
                className='bg-primary text-white hover:bg-[#446383] font-medium px-6 py-4 mr-1 rounded-full'
              >
                Continue
              </button>
            </div>
          )}
            </form>
          </div>
          <div className='pt-6 w-1/2 pr-3'>
            {' '}

          </div>
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center w-full h-screen'>
          <h2>Loading profile information...</h2>
          <Dna
            visible={true}
            height='100'
            width='100'
            ariaLabel='dna-loading'
            wrapperStyle={{}}
            wrapperClass='dna-wrapper'
          />
        </div>
      )}
    </div>
  );
};
