import { useState } from 'react';

interface ModalHook {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  toggleModal: VoidFunction;
  setSelectedContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  selectedContent: React.ReactNode;
}

export const useModal = (): ModalHook => {
  const [isActive, setIsActive] = useState(false);
  const [selectedContent, setSelectedContent] =
    useState<null | React.ReactNode>(null);
  const toggleModal = () => setIsActive(!isActive);

  return {
    isActive,
    setIsActive,
    toggleModal,
    setSelectedContent,
    selectedContent,
  };
};
