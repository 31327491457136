import { Link } from 'react-router-dom';

interface Props {
  name: string;
  route: string;
  icon: any;
  isActive: boolean;
}

const DashboardItem = ({ name, route, icon, isActive }: Props) => {
  const activeTabStyles = 'bg-subtitles text-white';
  const idleTabStyles = 'bg-transparent text-subtitles';

  return (
    <Link to={`/${route}`}>
      <li
        className={`${
          isActive ? activeTabStyles : idleTabStyles
        } py-4 cursor-pointer flex rounded`}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='25'
          fill='currentColor'
          className='bi bi-house-door mx-3'
          viewBox='0 0 16 16'
        >
          {icon}
        </svg>
        {name}
      </li>
    </Link>
  );
};

export default DashboardItem;
