import * as Sentry from '@sentry/react'
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify, Auth, Hub } from 'aws-amplify';
import awsconfig from 'aws-exports';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import routes from 'constants/routes';
import { Profile } from 'global/types';
import { prettifyErrorMessage } from 'helpers/aws_error_handling';
import { useLocalStorage } from 'hooks';
import { addDoctor, getDoctor } from 'services/awsFunctions';

Amplify.configure(awsconfig);

interface AuthContextType {
  signUp: (email: string, password: string) => Promise<CognitoUser | undefined>;
  resendConfirmationCode: (email: string) => Promise<void>;
  confirmSignUp: (username: string, code: string) => Promise<void>;
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  updateUserFullName: (name: string) => Promise<void>;
  requestPasswordRecoveryCode: (params: any, email: string) => Promise<void>;
  updateProfile: (doctor: any) => Promise<void>;
  submitForgotPassword: (
    email: string,
    code: string,
    new_password: string
  ) => Promise<void>;
  currentUser: any;
  currentProfile: any;
  profileCompleted: boolean;
  currentUserUuid?: string;
}

export let AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: (value: AuthContextType) => React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<CognitoUser | any>();
  const [getSavedProfile, setSavedProfile, clearLocalStorage] =
    useLocalStorage('current_profile', {});
  const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);
  const navigate = useNavigate();

  const profileCompleted = (currentUser?.attributes.name || '').trim().length > 0

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      const { event } = payload;

      if (event === 'tokenRefresh') {
        // Update current user when the token is refreshed, for example - name attribute has been changed
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);
      }

      if (event === 'signIn') {
        const user = payload.data
        if (currentUser?.attributes?.email === user.attributes.email) {
          // sign in already in progress
          return
        }

        setCurrentUser(user);
        const doctor = {
          email: user.attributes.email,
          doctorEmail: user.attributes.email,
          ID: user.attributes['custom:uuid'],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }

        await getDoctor(user.attributes['custom:uuid']).then(async (data) => {
          if (data.length <= 0) {
            await addDoctor(doctor).then(() => {
              setCurrentProfile(doctor as unknown as Profile);
              setSavedProfile(doctor);
              navigate(routes.dashboard.HOME);
            });
          } else {
            //set profile data in the local storage on first sign-in
            setSavedProfile(data[0]);
            setCurrentProfile(data[0]);
            navigate(routes.dashboard.HOME);
          }
        });
      }
    });
  }, []);

  const currentUserUUID = currentUser?.attributes?.['custom:uuid'];

  useEffect(() => {
    // get current profile from the local storage or redirect to login
    // to avoid additional re-fetching of the data on each reload, because we have the same and only one active user

    Auth.currentAuthenticatedUser().then((user) => {
      if (user !== null && !user.attributes.name) {
        navigate(routes.dashboard.HOME);
      } else if (getSavedProfile() && user) {
        setCurrentProfile(getSavedProfile());
        navigate(routes.dashboard.HOME);
      }

      setCurrentUser(user);
    }).catch((error) => { });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserUUID]);

  // Quick fix: refetch doctor profile each time current user changes.
  useEffect(() => {
    (async () => {
      if (currentUser) {
        await getDoctor(currentUser.attributes['custom:uuid']).then(async (data) => {
          if (data.length > 0) {
            setSavedProfile(data[0]);
            setCurrentProfile(data[0]);
          }
        })
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserUUID]);

  const signIn = async (username: string, password: string) => {
    try {
      await Auth.signIn(username, password)
    } catch (error: any) {
      if (error.name === 'UserNotConfirmedException') {
        throw new Error(error);
      } else {
        throw new Error(`${prettifyErrorMessage(error)}`);
      }
    }
  };

  const resendConfirmationCode = async (username: string) => {
    await Auth.resendSignUp(username).catch((error: Error) => {
      Sentry.captureException(error);
      throw error;
    });
  };

  const signUp = async (email: string, password: string) => {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          'custom:uuid': uuidv4(),
        },
        autoSignIn: {
          enabled: true,
        },
      });
      return user;
    } catch (error: any) {
      if (
        error
          .toString()
          .includes('An account with the given email already exists')
      ) {
        navigate(routes.LOGIN);
      }
      throw new Error(`${prettifyErrorMessage(error)}`);
    }
  };

  const confirmSignUp = async (username: string, code: string) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error: any) {
      throw new Error(`${prettifyErrorMessage(error)}`);
    }
  };

  const signOut = async () => {
    const promise = async () => await Auth.signOut();
    toast.promise(promise(), {
      loading: 'Finishing user`s session',
      success: (data) => {
        clearLocalStorage();
        setCurrentUser(null);
        setCurrentProfile(null);
        navigate(routes.LOGIN);
        return 'You have been signed out';
      },
      error: (error) => {
        return `${prettifyErrorMessage(error)}`;
      },
    });
  };

  const requestPasswordRecoveryCode = async (params: any, email: string) => {
    const promise = async () => await Auth.forgotPassword(email);
    toast.promise(promise(), params);
  };

  const submitForgotPassword = async (
    email: string,
    code: string,
    new_password: string
  ) => {
    const promise = async () =>
      Auth.forgotPasswordSubmit(email, code, new_password);
    toast.promise(promise(), {
      loading: 'Updating your password',
      success: (data) => {
        navigate(routes.LOGIN);
        return 'Your password was updated.';
      },
      error: (error) => {
        return `${prettifyErrorMessage(error)}`;
      },
    });
  };

  const updateUserFullName = async (name: string) => {
    // Updates name in cognito profile, disables lock on home page
    await Auth.updateUserAttributes(currentUser, {
      name: name,
    });
  }

  const updateProfile = async (doctor: any) => {
    setCurrentProfile(doctor as unknown as Profile);
    setSavedProfile(doctor)
  }

  let value = {
    signUp,
    signIn,
    signOut,
    updateUserFullName,
    resendConfirmationCode,
    confirmSignUp,
    requestPasswordRecoveryCode,
    submitForgotPassword,
    currentUser,
    currentProfile,
    profileCompleted,
    updateProfile,
    currentUserUuid: currentUser?.attributes['custom:uuid'] || undefined
  };

  return (
    <AuthContext.Provider value={value}>
      <div className='min-h-screen'>{children(value)}</div>
    </AuthContext.Provider>
  );
};
