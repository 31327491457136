import { SetStateAction, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

import routes from 'constants/routes';
import { AuthContext } from 'contexts/AuthContext';
import { prettifyErrorMessage } from 'helpers/aws_error_handling';

import { AuthFormTitle } from '../AuthFormTitle';

type Props = {
  setValidationStatus: (new_status: boolean) => void;
  updateEmail: React.Dispatch<SetStateAction<string | null>>;
};

type Inputs = {
  email: string;
  password: string;
  confrim_password: string;
};

export const SignUp = ({ setValidationStatus, updateEmail }: Props) => {
  const { signUp } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async ({ email, password }) => {
    toast.promise(signUp(email, password), {
      loading: 'Sending verification code...',
      success: (res) => {
        if (res !== undefined) {
          setValidationStatus(true);
          updateEmail(email);
        }
        return 'Check your email!';
      },
      error: (err) => `${prettifyErrorMessage(err)}`
    });
  };

  return (
    <div className='flex flex-col flex-1 justify-center items-center w-full h-screen bg-ghost-white'>
      <div className='max-w-xl w-full bg-white rounded-lg px-11 py-11'>
        <AuthFormTitle title='Registration' />

        <Link
            className='text-subtitles mt-10 cursor-pointer hover:underline block'
            to={routes.LOGIN}
          >
            « Registered already? Tap here
          </Link>

        <form
          onSubmit={handleSubmit(onSubmit)}
          onChange={async () => await trigger()}
          className='max-w-xl w-full px-4 mt-10 flex flex-col'
        >
          <div className='min-h-[70px]'>
            <div className='relative z-0'>
              <input
                type='email'
                id='email'
                {...register('email', {
                  required: {
                    value: true,
                    message: '',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                    message: 'Invalid email',
                  },
                })}
                name='email'
                className='block py-2.5 px-0 w-full text-md bg-transparent  appearance-none dark:focus:border-primary text-primary focus:outline-none focus:ring-0 peer'
                placeholder=' '
              />
              <label
                htmlFor='email'
                className='absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-subtitles peer-focus:dark:text-subtitles peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
              >
                Email
              </label>
              {errors.email && (
                <p className='text-red-500 text-[12px]'>{`${errors.email?.message}`}</p>
              )}
            </div>
          </div>
          <div className='min-h-[70px]'>
            <div className='relative z-0'>
              <input
                type='password'
                id='password'
                {...register('password', {
                  required: {
                    value: true,
                    message: '',
                  },
                  minLength: {
                    value: 8,
                    message: 'Password should be at least 8 characters',
                  },
                })}
                name='password'
                autoComplete='on'
                className='block py-2.5 px-0 w-full text-md bg-transparent  appearance-none dark:focus:border-primary text-primary focus:outline-none focus:ring-0 peer'
                placeholder=' '
              />
              <label
                htmlFor='password'
                className='absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-subtitles peer-focus:dark:text-subtitles peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
              >
                Password:
              </label>
              {errors.password && (
                <p className='text-red-500 text-[12px]'>{`${errors.password?.message}`}</p>
              )}
            </div>
          </div>
          <div className='min-h-[70px]'>
            <div className='relative z-0'>
              <input
                type='password'
                {...register('confrim_password', {
                  required: {
                    value: true,
                    message: '',
                  },
                  validate: (val: string) => {
                    if (watch('password') !== val) {
                      return 'Your passwords do no match';
                    }
                  },
                })}
                name='confrim_password'
                id='confrim_password'
                autoComplete='on'
                className='block py-2.5 px-0 w-full text-md bg-transparent  appearance-none dark:focus:border-primary text-primary focus:outline-none focus:ring-0 peer'
                placeholder=' '
              />
              <label
                htmlFor='confrim_password'
                className='absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-subtitles peer-focus:dark:text-subtitles peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
              >
                Confirm password
              </label>
              {errors['confrim_password'] && (
                <p className='text-red-500 text-[12px]'>{`${errors['confrim_password']?.message}`}</p>
              )}
            </div>
          </div>
          <button
            type='submit'
            className='rounded-full bg-primary hover:bg-police-blue text-white px-4 py-3 w-48 mt-5 self-center'
          >
            Signup
          </button>


        </form>
      </div>
    </div>
  );
};
