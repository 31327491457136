type FilterObjectsByKey = <T extends { [key: string]: any }>(
  array: T[],
  isSorted: boolean,
  toggleSorted: VoidFunction,
  keyToCompare: string
) => T[];

export const filterByKey: FilterObjectsByKey = (
  array,
  isSorted,
  toggleSorted,
  keyToCompare
) => {
  let sortedData: any = [];
  if (isSorted === false) {
    sortedData = array.sort((p1, p2) =>
      p1[keyToCompare] > p2[keyToCompare]
        ? 1
        : p1[keyToCompare] < p2[keyToCompare]
        ? -1
        : 0
    );
    toggleSorted();
  } else {
    sortedData = array.sort((p1, p2) =>
      p1[keyToCompare] < p2[keyToCompare]
        ? 1
        : p1[keyToCompare] > p2[keyToCompare]
        ? -1
        : 0
    );
    toggleSorted();
  }
  return sortedData;
};
