type Props = {
  openForm: VoidFunction;
};

export const PatientsButtons = ({ openForm }: Props) => {
  return (
    <div className='mb-4 flex'>
      <button
        type='button'
        className='rounded-full bg-primary hover:bg-police-blue text-white px-6 py-4 w-auto flex items-center justify-center mr-2'
        onClick={openForm}
      >
        New Patient
      </button>
    </div>
  );
};
