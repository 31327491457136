import React, { useEffect, useState } from 'react';

import { Patient } from 'global/types';

import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';

interface PatientsTableProps {
  patients: Patient[];
}

export const PatientsTable: React.FC<PatientsTableProps> = ({ patients }) => {
  const [sortedPatients, setSortedPatients] = useState(patients);

  // We must reset sorting to update the patient list with the latest data changes.
  // Sorting should be reimplemented in a functional manner,
  // meaning it should be possible to recalculate it when patient data changes.
  useEffect(() => {
    setSortedPatients(patients);
  }, [patients]);

  return (
    <table className='w-full text-sm text-left border-separate'>
      <TableHeader patients={sortedPatients} onSort={setSortedPatients} />
      <TableBody patients={sortedPatients} />
    </table>
  );
};
