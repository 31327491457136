import React from 'react';

export const ConsultationPage = () => {
  return (
    <div className='rounded-md border bg-ghost-white w-[755px] h-[505px] my-0 mx-auto'>
      {' '}
      <iframe
        src='https://empowersleep.as.me/schedule.php?appointmentType=24354603'
        height={'555px'}
        width={'750px'}
        title='apply for consultation'
        id='frame'
        className='mt-[-65px]'
      ></iframe>
    </div>
  );
};
