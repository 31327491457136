import { FieldValues, FieldPath, FieldErrors, FieldError } from 'react-hook-form';

interface FormFieldProps<T extends FieldValues, TName extends FieldPath<T>> {
  formName: string;
  name: TName;
  label: string;
  errors?: FieldErrors<T>;
  required?: boolean;
  className?: string;
  labelClassName?: string;
  errorClassName?: string;
  render: (name: TName, id: string, label: string) => React.ReactNode;
}

export const FormField = <T extends FieldValues, TName extends FieldPath<T>>(
  props: FormFieldProps<T, TName>
) => {
  const {
    formName, name, label, errors, required,
    className, labelClassName, errorClassName,
    render
  } = props;

  const id = `${formName}-${name}`;
  const error = errors? errors[name] as FieldError : undefined;

  return (
    <div className={className}>
      <label
        className={labelClassName}
        htmlFor={id}
      >{label}{required && ' *'}</label>
      {render(name, id, label)}
      {error &&
      <div className={errorClassName}>{error.message}</div>}
    </div>
  );
}
