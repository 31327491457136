import React from 'react';

import { Patient } from 'global/types';

import { TableRow } from './TableRow';

interface TableBodyProps {
  patients: Patient[];
}

export const TableBody: React.FC<TableBodyProps> = ({ patients }) => {
  return (
    <tbody>
      {patients.map((patient) => (
        <TableRow {...patient} key={patient.patientID} />
      ))}
    </tbody>
  );
};
