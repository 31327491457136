import React, { useEffect } from 'react';
import { useState } from 'react';
import { CaretDown } from 'react-bootstrap-icons';
import { CaretUp } from 'react-bootstrap-icons';

import { Patient } from 'global/types';
import { filterByKey } from 'helpers/functions';

interface TableHeaderProps {
  patients: Patient[];
  onSort: (patients: Patient[]) => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ patients, onSort }) => {
  const [isSorted, setIsSorted] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const toggleSorted = () => setIsSorted((prev) => !prev);
  const updateActiveIndex = (newIndex: number) =>
    setActiveIndex((prev) => newIndex);

  const sortByLastName = () => {
    const filteredData = filterByKey(
      patients,
      isSorted,
      toggleSorted,
      'lastName'
    );
    onSort([...filteredData]);
  };

  const sortByDOB = () => {
    const filteredData = filterByKey(
      patients,
      isSorted,
      toggleSorted,
      'dob'
    );
    onSort([...filteredData]);
  };
  const sortByGender = () => {
    const filteredData = filterByKey(
      patients,
      isSorted,
      toggleSorted,
      'gender'
    );
    onSort([...filteredData]);
  };
  const sortBySleepImage = () => {
    const filteredData = filterByKey(
      patients,
      isSorted,
      toggleSorted,
      'sleepImageID'
    );
    onSort([...filteredData]);
  };

  const headers = [
    {
      title: 'Name',
      clickHandler: sortByLastName,
    },
    {
      title: 'DOB',
      clickHandler: sortByDOB,
    },
    {
      title: 'Gender',
      clickHandler: sortByGender,
    },
    {
      title: 'SleepImage ID',
      clickHandler: sortBySleepImage,
    },
  ];

  return (
    <thead className='text-normal text-white  uppercase  bg-primary'>
      <tr>
        {headers.length > 0 &&
          headers.map((header, idx) => (
            <TableHeadData
              title={header.title}
              handleClick={header.clickHandler}
              changeActiveIndex={updateActiveIndex}
              activeIndex={activeIndex}
              currentIndex={idx}
              key={header.title}
            />
          ))}
        <th scope='col' className='py-3 px-6'>
          <span className='sr-only'>Patient details</span>
        </th>
      </tr>
    </thead>
  );
};

interface ITableHeaderData {
  title: string;
  activeIndex: number;
  currentIndex: number;
  handleClick: VoidFunction;
  changeActiveIndex: (newIndex: number) => void;
}

const TableHeadData = ({
  title,
  handleClick,
  activeIndex,
  currentIndex,
  changeActiveIndex,
}: ITableHeaderData) => {
  const [isCurrentActive, setIsCurrentActive] = useState(false);
  const [isSortedByColumn, setIsSortedByColumn] = useState(
    activeIndex === currentIndex
  );
  const updateActiveSorting = async () => {
    setIsCurrentActive(true);
    handleClick();
    changeActiveIndex(currentIndex);
    if (currentIndex !== activeIndex) {
      setIsSortedByColumn(true);
    } else {
      setIsSortedByColumn((prev) => !prev);
    }
  };

  useEffect(() => {
    setIsCurrentActive(activeIndex === currentIndex);
  }, [activeIndex, currentIndex]);

  return (
    <th
      scope='col'
      className='py-3 px-6 cursor-pointer'
      onClick={updateActiveSorting}
    >
      <div className='flex items-center'>
        <span className='mr-2'>{title}</span>
        {isCurrentActive ? (
          <div>{isSortedByColumn ? <CaretUp /> : <CaretDown />} </div>
        ) : (
          <CaretDown />
        )}
      </div>
    </th>
  );
};
