
type Props = {
  title: string;
}

export const AuthFormTitle = ({ title }: Props) => (
  <div className='flex items-center mb'>
    <img
      src='/assets/logo/iconLogo.svg'
      alt='icon logo'
      className='w-[17px] h-[29px] mr-3'
    />
    <h2 className='text-2xl text-primary'>
      {title}
    </h2>
  </div>
)

