import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes, Route, Navigate } from 'react-router-dom';

import { WelcomeLayout } from 'layouts/WelcomeLayout/WelcomeLayout';

import { ErrorBoundaryGuard } from './components';
import routes from './constants/routes';
import { AuthProvider } from './contexts/AuthContext';
import { DashboardLayout, PatientsLayout } from './layouts';
import {
  ConsultationPage,
  ErrorPage,
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  PatientPage,
  PatientsPage,
  ReadingsPage,
  Registration,
} from './pages';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        {({ profileCompleted }) => (
        <ErrorBoundaryGuard>
          <Routes>
            <Route path='/' element={<Navigate to='/login' replace />} />
            <Route path={routes.SIGN_UP} element={<Registration />} />
            <Route path={routes.LOGIN} element={<LoginPage />} />
            <Route
              path={routes.FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            />
            <Route element={profileCompleted ? <DashboardLayout /> : <WelcomeLayout />}>
            <Route element={<PatientsLayout />}>
              <Route
                index={true}
                path={routes.dashboard.PATIENTS}
                element={<PatientsPage />}
              />
              <Route
                path={routes.dashboard.PATIENT}
                element={<PatientPage />}
              />
            </Route>
            <Route path={routes.dashboard.HOME} element={<HomePage />} />
            <Route
              path={routes.dashboard.READINGS}
              element={<ReadingsPage />}
            />
              <Route
                path={routes.dashboard.CONSULATION}
                element={<ConsultationPage />}
              />
            </Route>
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </ErrorBoundaryGuard>
      )}
    </AuthProvider>
</QueryClientProvider>

  );
}

export default Sentry.withProfiler(App);
