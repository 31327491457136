import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Frame, ModalPortal } from 'components';
import { dashboardItems } from 'constants/data';
import { AuthContext } from 'contexts/AuthContext';
import { useModal } from 'hooks';

import DashboardItem from './DashboardItem';

export const DashboardNavigation: React.FC = () => {
  const [path, setPath] = useState('/patients');
  const { currentProfile, currentUser, profileCompleted } = useContext(AuthContext);
  const location = useLocation();
  const { setSelectedContent, selectedContent, isActive, setIsActive } =
    useModal();
  const openForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const link = e.currentTarget.attributes[1].value;
    setSelectedContent(
      <Frame link={link} closeFrame={() => setIsActive(false)} />
    );
    setIsActive(true);
  };

  useEffect(() => {
    if (location !== undefined) {
      setPath(location.pathname.split('/')[1]);
    }
  }, [location]);

  if (!profileCompleted) {
    return <div/>
  }

  return (
    <div className='w-80 flex flex-col bg-[#EFEFEF] pt-6'>
      <Link
        className='text-subtitles font-semibold text-3xl px-4 text-center'
        to={'./patients'}
      >
        <img
          src='/assets/logo/blackLogo.svg'
          alt='logo'
          className='h-[50px] sm:w-[210px] '
        />
      </Link>

      <div className='flex flex-col justify-between flex-1 rounded-sm'>
        <ModalPortal content={selectedContent} isActive={isActive} />
        <ul className='px-4 pt-2'>
          {dashboardItems.map((item) => (
            <div key={item.route}>
              <DashboardItem {...item} isActive={path === item.route} />
            </div>
          ))}
        </ul>
        <button
          className='pl-4 py-5 flex text-subtitles font-semibold text-sm border-t border-secondary'
          data-link={`https://hipaa.jotform.com/230028866756161?whatIs204=${
            currentProfile?.practiceName ? currentProfile?.practiceName : ''
          }&whatIs196=${currentUser ? currentUser.attributes.email : ''}`}
          onClick={openForm}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='22'
            height='22'
            fill='currentColor'
            className='bi bi-chat-left-dots mr-3'
            viewBox='0 0 16 16'
          >
            <path d='M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
            <path d='M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' />
          </svg>
          <span>Send Feedback</span>
        </button>
      </div>
    </div>
  );
};
