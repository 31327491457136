const prettifyErrorMessage = (error: string): string => {
  const errorMessage = error?.toString() || ''
  let prettifiedMessage: string = errorMessage.split(':')[1] || errorMessage.split(':')[0];

  if (errorMessage.includes('UserNotConfirmedException')) {
    return 'Please, verify email before login.'
  }

  if (errorMessage.includes('Username/client id combination not found.')) {
    return 'Email not found.';
  }

  if (errorMessage.includes('Incorrect username or password.')) {
    return 'Incorrect email or password.';
  }

  return prettifiedMessage;
};

export { prettifyErrorMessage };
