import React from 'react';

import { ConfirmationBox, Frame } from 'components';
import { jotFormLinks } from 'constants/data';

type Props = {
  propsForConfirmationBox: any;
  prefilledValues: any;
  toggleEditMode: VoidFunction;
  openModal: VoidFunction;
  closeModal: VoidFunction;
  setSelectedContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;
};

export const PatientButtons = ({
  propsForConfirmationBox,
  prefilledValues,
  toggleEditMode,
  openModal,
  closeModal,
  setSelectedContent,
}: Props) => {
  const openForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const link = e.currentTarget.attributes[1].value;
    setSelectedContent(<Frame link={link} closeFrame={closeModal} />);
    openModal();
  };

  const openDeleteConfirmation = () => {
    setSelectedContent(<ConfirmationBox {...propsForConfirmationBox} />);
    openModal();
  };

  return (
    <div className='mb-4 flex justify-between flex-wrap'>
      <div className='w-[920px] flex justify-between flex-wrap'>
        <button
          type='button'
          data-link={`${jotFormLinks.ref}?${prefilledValues}`}
          onClick={openForm}
          className='rounded-full bg-primary hover:bg-police-blue text-white px-6 py-4 w-auto flex items-center justify-center'
        >
          Refer Patient
        </button>
        <button
          type='button'
          data-link={`${jotFormLinks.hst}?${prefilledValues}`}
          onClick={openForm}
          className='rounded-full bg-primary hover:bg-police-blue text-white px-6 py-4 w-auto flex items-center justify-center'
        >
          Request Sleep Test Read
        </button>
        <button
          type='button'
          data-link={`${jotFormLinks.oralAppliance}?${prefilledValues}`}
          onClick={openForm}
          className='rounded-full bg-primary hover:bg-police-blue text-white px-6 py-4 w-auto flex items-center justify-center'
        >
          Request Rx for oral appliance therapy
        </button>
        <a
          href='https://empowersleep.account.box.com/'
          target={'_blank'}
          rel='noreferrer'
        >
          <button
            type='button'
            className='rounded-full bg-primary hover:bg-police-blue text-white px-6 py-4 w-auto flex items-center justify-center'
          >
            Patient records
          </button>
        </a>
      </div>

      <div className='flex-[0.16] flex justify-end items-center mr-5 py-2'>
        <button
          type='button'
          onClick={toggleEditMode}
          className='px-2'
          title='Edit patient’s data'
        >
          <span className='sr-only'>Click to edit</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='25'
            fill='currentColor'
            className='bi bi-pencil-square'
            viewBox='0 0 16 16'
          >
            <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
            <path
              fillRule='evenodd'
              d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
            />
          </svg>
        </button>

        <button
          type='button'
          className=' text-red-800 px-2'
          onClick={openDeleteConfirmation}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='25'
            fill='currentColor'
            className='bi bi-trash3'
            viewBox='0 0 16 16'
          >
            <path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z' />
          </svg>
        </button>
      </div>
    </div>
  );
};
