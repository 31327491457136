import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';

import { Footer } from 'components';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

Sentry.init({
  dsn: 'https://50771af7aaff44149707876d79a054fe@o4504282066386944.ingest.sentry.io/4504282089455616',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
  environment: (process.env.REACT_APP_AWS_ENV || 'local')
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Toaster position='top-center' reverseOrder={false} />
    <App />
    <Footer />
  </BrowserRouter>
);

reportWebVitals();
