export const textFieldPattern = /[a-zA-Z0-9._]/;
export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;
export const passwordPattern = /.{6}/;
export const hstDevicesPatter = /.{1,}/;
export const telPattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export enum ErrorMessages {
  REQUIRED = 'This field is required.'
}

export const normalizeUSPhoneNumber = (value: string) => {
  // Only keep the numbers.
  var normalizedValue = value.replace(/[^\d]/g, '');

  // Remove leading zeroes if any.
  if (normalizedValue.startsWith('00')) {
    normalizedValue = normalizedValue.replace(/^00/, '');
  }

  // Remove leading 1 if present.
  if (normalizedValue.startsWith('1')) {
    normalizedValue = normalizedValue.replace(/^1/, '');
  }

  if (normalizedValue.length >= 10) {
    // Return in international format.
    return '(' + normalizedValue.substring(0, 3) + ') ' +
      normalizedValue.substring(3, 6) + '-' +
      normalizedValue.substring(6, 10);
  }
  else {
    // Clear input value.
    return '';
  }
}
