import { API } from 'aws-amplify';
// import { apiName, PATHS } from 'constants/aws';

const API_NAME = 'empowersleepAPI';
const DOCTORS_PATH = '/doctors';
const PATIENTS_PATH = '/patients';

//TODO add types
const addDoctor = async (profileData: any) => {
  const data = {
    body: profileData,
  };
  return API.post(API_NAME, DOCTORS_PATH, data);
};
const updateDoctor = async (profileData: any) => {
  const data = {
    body: profileData,
  };
  return API.put(API_NAME, DOCTORS_PATH + '/update', data);
};
const updatePatient = async (profileData: any) => {
  const data = {
    body: profileData,
  };
  return API.put(API_NAME, PATIENTS_PATH + '/update', data);
};

const getDoctor = async (id: string) => {
  const path = `${DOCTORS_PATH}/${id}`;
  return API.get(API_NAME, path, {});
};

const addPatient = async (profileData: any) => {
  const data = {
    body: profileData,
  };
  return API.post(API_NAME, PATIENTS_PATH, data);
};

const getPatient = async (doctorID: string, patientID: string) => {
  const path = `${PATIENTS_PATH}/${doctorID}/${patientID}`;
  return API.get(API_NAME, path, {});
};

const getPatients = async (doctorID: string) => {
  const path = `${PATIENTS_PATH}?doctorID=${doctorID}`;
  return API.get(API_NAME, path, {});
};
const deletePatient = async (doctorID: string, patientID: string) => {
  const path = `${PATIENTS_PATH}/delete`;

  return API.del(
    API_NAME,
    path + `?doctorID=${doctorID}&patientID=${patientID}`,
    {}
  );
};

export {
  addDoctor,
  getDoctor,
  addPatient,
  getPatient,
  getPatients,
  deletePatient,
  updatePatient,
  updateDoctor,
};
