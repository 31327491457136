import * as Sentry from '@sentry/react';
import React, { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'contexts/AuthContext';

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div
      role='alert'
      className=' h-screen w-screen flex flex-col items-center justify-center'
    >
      <p className='text-subtitles font-semibold text-3xl mb-5'>
        Ooops...Something went wrong
      </p>
      <button
        onClick={resetErrorBoundary}
        className='rounded-full bg-primary hover:bg-police-blue text-white px-6 py-4 w-auto flex items-center justify-center mr-2'
      >
        Try again
      </button>
    </div>
  );
}

type Props = {
  children: React.ReactNode;
};

export const ErrorBoundaryGuard = ({ children }: Props) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        navigate('/');
      }}
      onError={(error) => {
        const eventID = Sentry.captureException(error);
        console.log(eventID);

        Sentry.showReportDialog({
          eventId: eventID,
          labelEmail: 'Provide your email',
          user: {
            email: currentUser.attributes.email,
            name: `${
              currentUser.attributes.name ? currentUser.attributes.name : ''
            }`,
          },
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
