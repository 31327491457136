import { contactInfo, footerLinks } from 'constants/data';

import gitInfo from '../../gitinfo.json';

export const Footer = () => {
  return (
    <div className='w-full bg-police-blue text-gray-300 flex justify-center items-center flex-wrap py-7'>
      <div className='flex flex-row md:flex-col justify-between mr-32'>
        <div>
          <div className='relative'>
            <img
              src='/assets/logo/whiteLogo.svg'
              alt='logo'
              className=' w-44 h-6'
            />
          </div>
          <div className='flex flex-col flex-wrap font-normal mt-10'>
            <p className='leading-[24px]'>
              Copyright © 2021 Empower Sleep, Inc.
            </p>
            <p className='leading-[24px] mt-2'>All rights reserved</p>
            <div className='mt-2'>Version: {gitInfo['gitCommitHash']}</div>
          </div>
          <div className='flex flex-wrap mt-10'>
            <div className='group w-8 h-8 rounded-full bg-[#ffffff2a] flex items-center justify-center mr-4 hover:bg-white'>
              <a
                href='https://www.instagram.com/empowersleep/'
                target={'_blank'}
                rel={'noreferrer'}
                className='group-hover:invert-[0.7]'
              >
                <img
                  src='/assets/social/insta.svg'
                  alt='insta'
                  className=' w-[18px] h-[19px]'
                />
              </a>
            </div>
            <div className='group w-8 h-8 rounded-full bg-[#ffffff2a] flex items-center justify-center hover:bg-white'>
              <a
                href='https://web.facebook.com/Empower-sleep-341690857069853?_rdc=1&amp;_rdr'
                className='group-hover:invert-[0.7]'
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img
                  src='/assets/social/twitter.svg'
                  alt='twitter'
                  className=' w-[18px] h-[19px]'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap'>
        {footerLinks.map((section) => (
          <div
            key={section.title}
            className='flex flex-col mx-[30px] w-[164px]'
          >
            <p className='leading-8 font-medium text-[18px] text-white mb-6'>
              {section.title}
            </p>
            <ul>
              {section.links.map((link, idx) => (
                <li
                  key={link.name}
                  className={`leading-6 ${
                    idx !== section.links.length - 1 ? 'mb-3' : ''
                  }`}
                >
                  <a href={link.link}>{link.name}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className='flex flex-col mx-[30px] w-[243px]'>
          <p className='leading-8 font-medium text-[18px] text-white mb-6'>
            Contact
          </p>
          <ul>
            {contactInfo.map((section) => (
              <li key={section.name}>
                {section.name}: {section.value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
